import $ from 'jquery'

// Mobile menu button
;(() => {
  const nav = $('.header__nav')[0]
  const navClosedClass = 'header__nav_closed'
  const navClose = () => nav.classList.add(navClosedClass)
  const navToggle = () => nav.classList.toggle(navClosedClass)
  navClose()
  nav.addEventListener('click', event => {
    event.stopPropagation()
    navToggle()
  })
  document.addEventListener('click', () => {
    navClose()
  })
})()

// Narrow header
;(() => {
  const header = $('.header')[0]
  const slider = $('.slider')[0]
  const update = () => {
    const scroll = document.documentElement.scrollTop || document.body.scrollTop

    scroll <= 16 // arbitrary threshold
      ? header.classList.remove('header_narrow')
      : header.classList.add('header_narrow')

    if (slider) {
      const position = slider.offsetTop + slider.offsetHeight
      scroll > position - 64
        ? header.classList.add('header_solid')
        : header.classList.remove('header_solid')
    }
  }
  document.addEventListener('scroll', update)
  update()
})()

// Homepage slider
;(() => {
  const slides = $('.slider__slides')[0]
  if (!slides) return
  const duration = 4000
  const transition = 400
  const transitionSpeed = (transition / 1000) + 's'
  setInterval(() => {
    slides.style.transition = 'left ' + transitionSpeed
    slides.style.left = '-100vw'
    setTimeout(() => {
      slides.style.transition = 'none'
      slides.style.left = '0'
      slides.appendChild(slides.children[0])
    }, transition)
  }, duration)
})()

// Open external links in new tab
;(() => {
  const externalHost = a => a.hostname && a.hostname !== location.hostname
  const mailto = a => a.protocol === 'mailto:'
  const phone = a => a.protocol === 'tel:'
  const pdf = a => a.href.match(/\.pdf$/)
  $('a').toArray()
    .filter(a => externalHost(a) || mailto(a) || phone(a) || pdf(a))
    .forEach(a => a.target = '_blank')
})()

// Prevent underscore bullet from showing up for link lists
;(() => {
  $('li > a').toArray()
    .forEach(a => a.parentElement.classList.add('link'))
})()

// Smooth scroll for page links
;(() => {
  $('a').toArray()
    .map(a => [a.getAttribute('href'), a])
    .filter(([href]) => href && href[0] === '#')
    .forEach(([href, a]) => {
      a.addEventListener('click', e => {
        const target = $(`${href}, a[name="${href.substr(1)}"]`)[0]
        if (!target) return
        e.preventDefault()
        $('html, body').animate({
          scrollTop: $(target).offset().top
        }, 300)
      })
    })
})()

// Timeline
;(() => {
  const items = $('.timeline__item').toArray()
  items.forEach(item => {
    item.addEventListener('click', () => {
      item.classList.toggle('timeline__item_active')
    })
  })
})()
